<template>
  <div class="login">
    <div class="login__language">
      <span class="login__language__text" :class="{'login__language--active': language === 'en'}" @click="handleLanguage('en')">English</span>
      <span class="login__language--margin">|</span>
      <span class="login__language__text" :class="{'login__language--active': language === 'zh' || language === 'zh-CN'}" @click="handleLanguage('zh')">中文</span>
    </div>
    <div class="img-form">
      <img :src="require(`@/assets/${app_name}/logo.svg`)">
      <span>{{'Sales Center'}}</span>
    </div>
    <el-form
      v-if="!visible"
      class="login-form"
      label-position="top"
      ref="form"
      :model="form"
      :rules="rules"
      label-width="80px"
    >
      <el-form-item prop="email">
        <el-input v-model="form.email" :placeholder="$t('placeholder.mail')">
          <template #prefix>
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="password">
        <el-input :type="inputType" v-model="form.password" :placeholder="$t('placeholder.password')">
          <template #prefix>
            <i class="el-icon-lock"></i>
          </template>
          <i
            class="iconfont"
            :class="inputType === 'password' ? 'icon-eye' : 'icon-noeye'"
            slot="suffix"
            @click="changeInputType"
          >
          </i>
        </el-input>
      </el-form-item>
       <el-form-item>
         <div class="form-btn-box">
          <el-checkbox v-model="checked" size="mini">{{$t('login.rememberMe')}}</el-checkbox>
          <el-button type="text" @click="forgetPassWord(true)">{{$t('login.forgetPWD')}}</el-button>
         </div>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          :loading="isLoginLoading"
          @click="onSubmit"
        >{{$t('button.logIn')}}</el-button>
      </el-form-item>
    </el-form>
    <el-form
      v-else
      class="login-form"
      label-position="top"
      ref="forgetForm"
      :model="forgetForm"
      :rules="forgetRules"
      label-width="80px">
      <el-form-item prop="email">
        <el-input v-model.trim="forgetForm.email" :placeholder="$t('placeholder.mail')">
          <template #prefix>
            <i class="el-icon-user"></i>
          </template>
        </el-input>
      </el-form-item>
       <el-form-item>
         <div class="form-btn-box-right">
          <!-- <el-checkbox v-model="checked" size="mini">记住我</el-checkbox> -->
          <el-button type="text" @click="forgetPassWord(false)">{{$t('button.logIn')}}</el-button>
         </div>
      </el-form-item>
      <el-form-item>
        <el-button
          class="login-btn"
          type="primary"
          :loading="isForgetLoading"
          @click="onForget"
        >{{$t('button.resetPassword')}}</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import { login, sendResetPasswordEmail, getPermission } from '@/services/user'
// putManagerLang
export default {
  name: 'LoginIndex',
  data () {
    return {
      app_name: process.env.VUE_APP_NAME,
      inputType: 'password',
      language: this.$cookies.get('language') || navigator.language.split('-')[0] || navigator.userLanguage.split('-')[0] || 'en',
      form: {
        email: '',
        password: ''
      },
      rules: {
        // phone: [
        //   { required: true, message: '请输入手机号', trigger: 'blur' },
        //   { pattern: /^1\d{10}$/, message: '请输入正确的手机号', trigger: 'blur' }
        // ],
        // password: [
        //   { required: true, message: '请输入密码', trigger: 'blur' },
        //   { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        // ]
      },
      forgetRules: {
        email: [
          { required: true, message: this.$t('placeholder.mail'), trigger: 'blur' },
          { pattern: /^(.+?)@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/, message: this.$t('placeholder.rightMail'), trigger: 'blur' }
        ]
      },
      forgetForm: {
        email: ''
      },
      isForgetLoading: false,
      isLoginLoading: false,
      checked: false,
      visible: false
    }
  },
  created () {
    console.log(this.language)
    // console.log(this.$cookies.get('history'))
    if (this.$cookies.get('history')) {
      const { email, password, checked } = this.$cookies.get('history')
      this.form = { email, password }
      this.checked = checked
    }
  },
  methods: {
    changeInputType () {
      if (this.inputType === 'password') {
        this.inputType = 'text'
      } else {
        this.inputType = 'password'
      }
    },
    // 国际化语言修改
    handleLanguage (val) {
      // console.log(val)
      this.language = val
      this.$cookies.set('language', this.language)
      // console.log(this.$cookies)
      location.reload()
    },
    // 切换画面
    forgetPassWord (flag) {
      if (!flag) {
        this.$refs.forgetForm.clearValidate('email')
        this.$refs.forgetForm.email = ''
      }
      this.visible = flag
    },
    // 重置
    async onForget () {
      try {
        if (await this.$refs.forgetForm.validate() === true) {
          this.isLoginLoading = true
          const data = await sendResetPasswordEmail({ admin_user: this.forgetForm })
          if (data) {
            this.isLoginLoading = false
            this.$message.success(this.$t('message.sentSuccess'))
          }
        }
      } catch (err) {}
      this.isForgetLoading = false
    },
    async onSubmit () {
      const $this = this
      try {
        // 1. 表单验证
        // await this.$refs.form.validate()

        // 登录按钮 loading
        this.isLoginLoading = true
        const IS_PRODUCTION = process.env.NODE_ENV == 'production'
        // 2. 验证通过 -> 提交表单
        let loginRes = {}
        if (!IS_PRODUCTION) {
          const params = {
            admin_user: {
              email: this.form.email,
              password: this.$encryptWithRSA(this.form.password)
            },
            decrypt: true
          }
          const { data } = await login( params )
          loginRes = data
        } else {
          const { data } = await login( {admin_user: this.form} )
          loginRes = data
        }
        console.log(loginRes)
        // const params = {
        //   admin_user: {
        //     email: this.form.email,
        //     password: this.$encryptWithRSA(this.form.password)
        //   },
        //   decrypt: true
        // }
        // const { data } = await login( params )
        // 3. 处理请求结果
        //    失败：给出提示
        // console.log(data.admin_user)
        if (!loginRes.admin_user) {
          this.$message.error(loginRes.message)
        } else {
          if (this.checked) {
            const obj = {}
            Object.assign(obj, this.form, { checked: this.checked })
            this.$cookies.set('history', JSON.stringify(obj))
          } else {
            this.$cookies.set('history', undefined)
          }
          // const languageMap = { zh: 'zh-CN', en: 'en' }
          // putManagerLang(data.admin_user.id, { locale: languageMap[this.language] })
          // 1. 登录成功，记录登录状态，状态需要能够全局访问（放到 Vuex 容器中）
          $this.$store.commit('setUser', loginRes.admin_user)
          $this.$store.commit('setRole', loginRes.admin_user.role)
          // 2. 然后在访问需要登录的页面的时候判断有没有登录状态（路由拦截器）
          //    成功：跳转回原来页面或首页
          // console.log(this.$route.query)
          if (this.app_name !== 'combine') {
            const permissions = await getPermission()
            $this.$store.commit('setPermission', permissions.data)
          }
          if (this.app_name === 'zoo') {
            this.$router.push(this.$route.query.redirect || '/ordersManager/ticketOrders/list')
          } else {
            this.$router.push(this.$route.query.redirect || '/')
          }

          if (!this.$route.query.redirect) {
            this.$store.commit('app/TOGGLE_NAME', '')
          }
          this.$message.success(this.$t('message.loginSuccess'))
        }
      } catch (err) {
        // console.log('登录失败', err)
      }

      // 结束登录按钮的 loading
      this.isLoginLoading = false
    }
  }
}
</script>

<style lang="scss">
.login {
  .el-icon-loading {
    font-size: 14px !important;
  }
  .el-checkbox {
    .el-checkbox__label {
      font-size: 12px;
    }
  }
}
</style>
<style lang="scss" scoped>
.login {
   &__language {
    margin-bottom: 10px;
    font-size: 12px;
    color: #909399;
    &--margin {
      margin: 0 5px;
    }
    &__text{
      cursor: pointer;
    }
    &--active {
      color: #40586F;
    }
  }
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: linear-gradient( 135deg, #d9ddd9 10%, #eaebec 100%);
  .img-form {
    width: 300px;
    padding: 30px 20px 30px 20px;
    text-align: center;
    background: rgba(255, 255 , 255, .8);
    border-radius: 5px 5px 0 0 ;
    display: flex;
    flex-direction: column;
    > span {
      font-size: 20px;
      margin-top: 8px;
      // font-weight: 500;
      font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
    }
    > img {
      padding: 20px;
    }
  }
  .form-btn-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .form-btn-box-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  .login-form {
    width: 300px;
    background: rgba(255, 255 , 255, .8);
    padding: 20px;
    border-radius: 0 0 5px 5px;
  }
  .login-btn {
    width: 100%;
  }
}
</style>
